// import {
//   ExampleImage,
//   SystemType,
//   SystemTypeData,
// } from 'types/lead-qualification';
// import { buildSystemImageString } from 'utils/helpers';

// import CHPStock0 from 'public/images/installed_systems/chp/stock/chp_stock_01.jpg';
// import CHPStock1 from 'public/images/installed_systems/chp/stock/chp_stock_02.jpg';
// import CHPBryantAirHandler0 from 'public/images/installed_systems/chp/bryant/air_handler_0.jpg';
// import CHPBryantAirHandler1 from 'public/images/installed_systems/chp/bryant/air_handler_1.jpg';
// import CHPBryantCondenser0 from 'public/images/installed_systems/chp/bryant/condenser_0.jpg';
// import CHPBryantCondenser1 from 'public/images/installed_systems/chp/bryant/condenser_1.jpg';
// import CHPBryantCondenser2 from 'public/images/installed_systems/chp/bryant/condenser_2.jpg';
// import CHPBryantCondenser3 from 'public/images/installed_systems/chp/bryant/condenser_3.jpg';
// import CHPECOERAirHandler0 from 'public/images/installed_systems/chp/ecoer/air_handler_0.jpg';
// import CHPECOERAirHandler1 from 'public/images/installed_systems/chp/ecoer/air_handler_1.jpg';
// import CHPECOERCondenser0 from 'public/images/installed_systems/chp/ecoer/condenser_0.jpg';
// import CHPECOERCondenser1 from 'public/images/installed_systems/chp/ecoer/condenser_1.jpg';
// import CHPECOERCondenser2 from 'public/images/installed_systems/chp/ecoer/condenser_2.jpg';

// import CombinationBoilerNavien0 from 'public/images/installed_systems/combination_boiler/navien/boiler_0.jpg';
// import CombinationBoilerNavien1 from 'public/images/installed_systems/combination_boiler/navien/boiler_1.jpg';
// import CombinationBoilerNavien2 from 'public/images/installed_systems/combination_boiler/navien/boiler_2.jpg';

// import DMSStock1 from 'public/images/installed_systems/dms/stock/dms_stock_01.jpg';
// import DMSStock2 from 'public/images/installed_systems/dms/stock/dms_stock_02.jpg';
// import DMSStock3 from 'public/images/installed_systems/dms/stock/dms_stock_03.jpg';
// import DMSStock4 from 'public/images/installed_systems/dms/stock/dms_stock_04.jpg';
// import DMSStock5 from 'public/images/installed_systems/dms/stock/dms_stock_05.jpg';
// import DMSStock6 from 'public/images/installed_systems/dms/stock/dms_stock_06.jpg';
// import DMSStock7 from 'public/images/installed_systems/dms/stock/dms_stock_07.jpg';

// import FurnaceBryant0 from 'public/images/installed_systems/furnace/bryant/furnace_0.jpg';
// import FurnaceBryant1 from 'public/images/installed_systems/furnace/bryant/furnace_1.jpg';
// import FurnaceBryant2 from 'public/images/installed_systems/furnace/bryant/furnace_2.jpg';

// import FurnaceACBryantCondenser0 from 'public/images/installed_systems/furnace_ac/bryant/condenser_0.jpg';
// import FurnaceACBryantFurnace0 from 'public/images/installed_systems/furnace_ac/bryant/furnace_0.jpg';
// import FurnaceACBryantFurnace1 from 'public/images/installed_systems/furnace_ac/bryant/furnace_1.jpg';
// import FurnaceACBryantFurnace2 from 'public/images/installed_systems/furnace_ac/bryant/furnace_2.jpg';

// import GasBoilerNavien0 from 'public/images/installed_systems/gas_boiler/navien/boiler_0.jpg';
// import GasBoilerNavien1 from 'public/images/installed_systems/gas_boiler/navien/boiler_1.jpeg';
// import GasBoilerNavien2 from 'public/images/installed_systems/gas_boiler/navien/boiler_2.jpg';
// import GasBoilerNavien3 from 'public/images/installed_systems/gas_boiler/navien/boiler_3.jpg';
// import GasBoilerNavien4 from 'public/images/installed_systems/gas_boiler/navien/boiler_4.jpg';
// import GasBoilerWeilMclain0 from 'public/images/installed_systems/gas_boiler/weil-mclain/boiler_0.png';
// import GasBoilerWeilMclain1 from 'public/images/installed_systems/gas_boiler/weil-mclain/boiler_1.png';

// import ACExample from 'public/images/system_types/ac_example.jpeg';
// import CHPExample from 'public/images/system_types/chp_example.jpeg';
// import CombinationBoilerExample from 'public/images/system_types/combo-boiler_example.jpeg';
// import DMSExample from 'public/images/installed_systems/dms/stock/dms_stock_03.jpg';
// import FurnaceACExample from 'public/images/system_types/furnace-ac_example.jpeg';
// import FurnaceExample from 'public/images/system_types/furnace_example.jpeg';
// import FurnaceHPExample from 'public/images/system_types/furnace-heat-pump_example.png';
// import HighEfficiencyBoilerExample from 'public/images/system_types/high-efficiency-boiler_example.png';
// import HotWaterTankExample from 'public/images/system_types/hot-water-tank_example.jpeg';
// import OilBoilerExample from 'public/images/system_types/oil-boiler_example.png';
// import OilFurnaceExample from 'public/images/system_types/oil-furnace_example.png';
// import StandardBoilerExample from 'public/images/system_types/standard-boiler_example.png';
// import SteamBoilerExample from 'public/images/system_types/steam-boiler_example.png';

// import AOSmithHeatPumpWaterHeater from 'public/images/customizations/ao_smith_hpts_50n.png';
// import IBCIndirectHotWaterHeater from 'public/images/customizations/heat_flo_hf50i.png';
// import HTPStandardGasStorageTank from 'public/images/customizations/htp_ssu_45cb.png';
// import NavienTanklessHotWaterHeater from 'public/images/customizations/navien_npe_180a2.png';
// import RheemHeatPumpWaterHeater from 'public/images/customizations/rheem_proph50_t2_rh375-30@1x.png';
// import RheemEletricResistanceWaterHeater from 'public/images/customizations/rheem_proe50-m2rh95@1x.png';

// import CHPEcoerAirhandler from 'public/images/equipment/chp-ecoer-airhandler.png';
// import CHPEcoerCondenser from 'public/images/equipment/chp-ecoer-condenser.png';
// import CHPBryantAirhandler from 'public/images/equipment/chp-bryant-airhandler.png';
// import CHPBryantCondenser from 'public/images/equipment/chp-bryant-condenser.png';
// import ACBryantEvolutionCondenser from 'public/images/equipment/ac-bryant-evolution-condenser.png';
// import ACBryantLegacyAirhandler from 'public/images/equipment/ac-bryant-legacy-airhandler.png';
// import ACBryantLegacyCondenser from 'public/images/equipment/ac-bryant-evolution-condenser.png';
// import ACBryantPreferredAirhandler from 'public/images/equipment/ac-bryant-preferred-airhandler.png';
// import ACBryantPreferredCondenser from 'public/images/equipment/ac-bryant-preferred-condenser.png';
// import FurnaceAmericanStandardSilver from 'public/images/systems/america-standard_furnace-air-conditioner_silver.png';
// import FurnaceACAmericanStandardCondenser from 'public/images/equipment/furnace-ac-american-standard-condenser.png';
// import FurnaceACBryantLegacyFurnace from 'public/images/equipment/furnace-ac-bryant-legacy-furnace.png';
// import FurnaceACBryantEvolutionCondenser from 'public/images/equipment/furnace-ac-bryant-evolution-condenser.png';
// import FurnaceACBryantLegacyCondenser from 'public/images/equipment/furnace-ac-bryant-legacy-condenser.png';
// import FurnaceHPBryantEvolutionExtremeCondenser from 'public/images/equipment/furnace-hp-bryant-evolution-extreme-condenser.png';
// import FurnaceHPBryantPreferredCondenser from 'public/images/equipment/furnace-hp-bryant-preferred-condenser.png';

const HOT_JAR_IDS = {
  contractorDashboard: 3364899,
  documentUpload: 3167597,
  eComm: 3579884,
  homeownerDashboard: 3364901,
  proposal: 3392615,
};

const ECOMMERCE_ENVIRONMENTS_URLS = {
  LOCAL: 'http://localhost:3000',
  STAGING: 'https://dev.shop.tetra.com',
  PRODUCTION: 'https://shop.tetra.com',
};

const PAGE_SIZE = 10;

const CONTRACTOR_DASHBOARD_URL = '/co/dashboard/';
const CONTRACTOR_LOGIN_TRACKING_EVENT = 'co_dash_login_attempt';

const HOMEOWNER_DASHBOARD_URL = '/homeowner/';
const HOMEOWNER_LOGIN_TRACKING_EVENT = 'ho_dash_login_attempt';

// const GOALS_RANKING_CARDS = {
//   final_price: {
//     text: "You're moving soon or you want an option that's easy on your wallet.",
//     title: 'Low upfront cost',
//   },
//   efficiency: {
//     text: 'Optimize for the lowest monthly cost. Best if staying in your house for more than 5 years. ',
//     title: 'Efficiency rating',
//   },
//   warranty: {
//     text: 'We only recommend high quality equipment, but some have longer warranties than others.',
//     title: 'Warranty',
//   },
//   wifi: {
//     text: 'You want to prioritize integrating with a smart home setup or controlling your system remotely.',
//     title: 'Technology',
//   },
//   sound_level: {
//     text: "While most don't notice, a quieter brand can be helpful for very light sleepers.",
//     title: 'Noise level',
//   },
// };

// export function isKeyOfTypeOfNewSystemTypeData(
//   value: unknown
// ): value is keyof typeof SYSTEM_TYPE_DATA {
//   return (
//     typeof value === 'string' &&
//     [
//       'Central heat pumpCentral heat pump',
//       'FurnaceFurnace & heat pump',
//       'FurnaceFurnace & AC',
//       'FurnaceFurnace',
//       'BoilerHigh efficiency boiler',
//       'BoilerStandard boiler',
//       'BoilerSteam boiler',
//       'BoilerOil boiler',
//       'FurnaceOil furnace',
//       'Hot water tankOnly hot water tank',
//       'Air conditionerAir conditioner',
//       'BoilerCombination boiler',
//     ].includes(value)
//   );
// }

// export const SYSTEM_TYPE_DATA: SystemTypeData = {
//   'Central heat pumpCentral heat pump': {
//     badges: ['largestRebates', 'lowestFinalCosts'],
//     description: 'Replace your current system with a central heat pump.',
//     exampleImage: CHPExample.src,
//     exampleImages: [
//       {
//         image: CHPStock0.src,
//         caption: '',
//       },
//       {
//         image: CHPStock1.src,
//         caption: '',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           'Central Heat Pumps are highly efficient electric heating and air conditioning systems. Simply put, the heat pump absorbs warm air and moves it from one place to another. In the summer time, it pulls warmth and moisture from inside your home and sends it outside using the refrigeration process. In the winter time, this process can be reversed by extracting warmth and moisture from outdoor temperatures down into the teens and pulling it into the home to provide heat.',
//         question: 'How is a heat pump fueled?',
//       },
//       {
//         answer:
//           'We only install heat pumps that are capable of heating in extremely cold temperatures. Our top tier models can heat down into the teens. When the temperature drops below 12 degrees Fahrenheit, back-up heat can be used to heat your home.',
//         question:
//           "What if it's below zero degrees outside? Is the heat pump still able to provide heat?",
//       },
//       {
//         answer:
//           'It is very difficult to calculate operating costs because there are so many variables &mdash; the insulation value of your home, number and type of windows, natural drafts, condition and size of the existing ductwork, electricity rates in your area, etc. However, case studies in New England and the Great Lakes regions show that heat pump annual operating costs are less than fossil-fueled furnaces and traditional air conditioning systems. Case studies also show that customers start saving in the first year.',
//         question: 'How much will I spend on electricity',
//       },
//       {
//         answer:
//           "The electrical usage (amperage) for a heat pump condenser depends on the size of the unit you need. Before we install the equipment, we'll confirm that your panel has the capacity to support the new system. If you need an electrical upgrade, we can bundle this into our project or you can work with your own electrician to prepare your panel.",
//         question: 'What are the electrical requirements?',
//       },
//     ],
//     type: 'central_heat_pump',
//   },
//   'Ductless heat pumpHeat pump': {
//     badges: ['mostPopular', 'lowestFinalCosts', 'largestRebates'],
//     description: 'Replace current system with a ductless heat pump.',
//     exampleImage: DMSExample.src,
//     exampleImages: [
//       {
//         image: DMSStock1.src,
//         caption:
//           'An outdoor unit with line set connecting it to the indoor unit.',
//       },
//       {
//         image: DMSStock2.src,
//         caption: 'An outdoor unit for a heat pump, also known as a condenser.',
//       },
//       {
//         image: DMSStock3.src,
//         caption: 'An indoor wall unit for a ductless heat pump.',
//       },
//       {
//         image: DMSStock4.src,
//         caption: 'An indoor floor unit for a ductless heat pump.',
//       },
//       {
//         image: DMSStock5.src,
//         caption: 'An indoor wall unit for a ductless heat pump.',
//       },
//       {
//         image: DMSStock6.src,
//         caption: 'A ceiling unit for a ductless heat pump.',
//       },
//       {
//         image: DMSStock7.src,
//         caption: 'A ceiling unit for a ductless heat pump.',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           'Your ductless mini split system has an indoor and outdoor component. It is a fully electric system with an indoor air handler (head) that distributes warm/cool air into your living spaces and an outdoor condenser (heat pump). The two are connected with an electrical line and refrigerant line. These lines are typically run along the exterior of your home covered in a gutter-like material called line set. These come in white, brown, or black and can be painted over.',
//         question: 'What will my new system look like--inside and outside?',
//       },
//       {
//         answer:
//           'The size of the unit will depend on the air handler style (wall mounted, floor console, ceiling cassette) and the size of the unit we recommend for each area in your home. Our most popular head style, the wall mount, is a rectangle that requires three to four feet of horizontal wall space (approximately the length of a baseball bat). The condenser is more square than rectangular and the size will differ depending on the output of the unit. These condensers are typically more compact than a traditional air conditioning condenser and are mounted on an 18” stand within three feet of the foundation of your home.',
//         question: 'What are the dimensions of the indoor and outdoor units?',
//       },
//       {
//         answer:
//           'It is very difficult to calculate operating costs because there are so many variables-the insulation value of your home, number and type of windows, natural drafts, electricity rates in your area, etc. However, case studies conducted in the last ten-years in New England, the Great Lakes regions, and even Alaska show that heat pump annual operating costs are less than fossil-fueled furnaces and traditional air conditioning systems. Case studies also show that customers start saving in the first year. These estimates are contingent on consistency in utility rates.',
//         question: 'How much will I spend on electricity?',
//       },
//       {
//         answer:
//           "The electrical usage (amperage) for a ductless mini split system depends on the size of the unit you need. Before we install the equipment, we'll confirm that your panel has the capacity to support the new system. If you need an electrical upgrade, we can bundle this into our project or you can work with your own electrician to prepare your panel.",
//         question: 'What are the electrical requirements?',
//       },
//     ],
//     type: 'dms',
//   },
//   'FurnaceFurnace & heat pump': {
//     badges: [],
//     description: 'Replace your old furnace with a new furnace and heat pump.',
//     exampleImage: FurnaceHPExample.src,
//     exampleImages: [],
//     faq: [
//       {
//         answer:
//           'Yes, the furnace is 97% efficient and the heat pump is 20+ SEER! The efficiency rating of this system exceeds the Mass Save minimum requirement.',
//         question: 'Will this system heat and cool my home efficiently?',
//       },
//       {
//         answer:
//           'The furnace and heat pump will look every similar to the existing furnace and air conditioning system, but may differ slightly in size. The new indoor and outdoor units will likely be taller. The outdoor condenser will be mounted on a stand.',
//         question: 'What will this system look like?',
//       },
//       {
//         answer:
//           'Yes! We only install cold climate heat pumps. These models are designed specifically for regions in which the heat pump will have to perform in the single digits. We will run a load calculation to determine the correct size for your specific home and confirm that the system will meet your heating load down to design conditions. When temperatures drop below the average coldest temperature, you will have a back-up electric resistance coil to ensure you will still be able to heat the home.',
//         question: 'Can a heat pump perform in Massachusetts?',
//       },
//       {
//         answer:
//           'This is a high efficiency, variable speed system. A variable speed blower motor saves you money and energy because it is a modulating system designed to make small adjustments to maintain the temperature in your home. It keeps you comfortable because the system is not blasting on and off at full capacity or spiking the temperature rapidly.',
//         question:
//           'How does this system differ from a regular furnace and air conditioning system?',
//       },
//       {
//         answer:
//           'There are too many variables to do an accurate cost-projection, but case studies in cold climates over the past five to ten years show that these systems are more affordable in terms of annual operating costs than traditional fossil fueled heating systems or standard central air, window, or portable air conditioning systems. Heat pumps are the most efficient heating/cooling technology on the market because they can produce 300% more energy than they consume. Rather than burn fuel to produce heat, a heat pump moves warm air from one place to another similar to the way a refrigerator works.',
//         question: 'How much money will this system save me on my utilities?',
//       },
//     ],
//     type: 'high_efficiency_furnace_heat_pump',
//   },
//   'FurnaceFurnace & AC': {
//     badges: [],
//     description:
//       'Replace your old furnace with a new furnace and air conditioner.',
//     exampleImage: FurnaceACExample.src,
//     exampleImages: [
//       {
//         image: FurnaceACBryantCondenser0.src,
//         caption: '',
//       },
//       {
//         image: FurnaceACBryantFurnace0.src,
//         caption: '',
//       },
//       {
//         image: FurnaceACBryantFurnace1.src,
//         caption: '',
//       },
//       {
//         image: FurnaceACBryantFurnace2.src,
//         caption: '',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           "We install oil, propane, and gas furnaces. We carry several brands &mdash; Bryant, American Standard, and Rheem—which offer varying levels of efficiency. If it's within your budget, we always recommend going for a high efficiency furnace. We offer large rebates through the Mass Save rebate program, which make a high-efficiency model more affordable.",
//         question: 'What types of furnaces do you install?',
//       },
//       {
//         answer:
//           'High efficiency systems are quiet, heat your home more comfortably, and use less fuel. This means they save you money and reduce your carbon footprint. We offer large rebates for high efficiency furnaces through the Mass Save rebate program, which can make high-efficiency systems more affordable than traditional systems.',
//         question: 'What are the benefits of a high-efficiency system?',
//       },
//       {
//         answer:
//           "One of Tetra's fully vetted, top-quality installers will handle the job. We only work with the best local pros.",
//         question: 'Who is doing the installation?',
//       },
//       {
//         answer:
//           'No. Our pricing is firm. The cost of your project is what you see above—no matter what happens on installation day.',
//         question:
//           'Will I be charged extra if something unexpected happens on installation day?',
//       },
//       {
//         answer:
//           "Your warranty kicks in as soon as your system is installed. Please note, your warranty is through the manufacturer of your system, not through Tetra. Coverage varies depending on the manufacturer, so you'll need to check your specific warranty to see what's covered. You don't have to pay for anything that's covered under your warranty. Many warranties require things like online registration or proof of annual maintenance, so it's smart to check the details of your warranty right away. If you ever have issues with your system or questions about your warranty, please give us a call at (413) 930-4281.",
//         question:
//           "How does the warranty work? If there are any issues, who do I call? Does Tetra fix it? What's covered?",
//       },
//     ],
//     type: 'furnace_air_conditioner',
//   },
//   FurnaceFurnace: {
//     badges: [],
//     description: 'Replace your old furnace with a new one.',
//     exampleImage: FurnaceExample.src,
//     exampleImages: [
//       {
//         image: FurnaceBryant0.src,
//         caption: '',
//       },
//       {
//         image: FurnaceBryant1.src,
//         caption: '',
//       },
//       {
//         image: FurnaceBryant2.src,
//         caption: '',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           "We install oil, propane, and gas furnaces. We carry several brands &mdash; Bryant, American Standard, and Rheem—which offer varying levels of efficiency. If it's within your budget, we always recommend going for a high efficiency furnace. We offer large rebates through the Mass Save rebate program, which make a high-efficiency model more affordable.",
//         question: 'What types of furnaces do you install?',
//       },
//       {
//         answer:
//           'High efficiency systems are quiet, heat your home more comfortably, and use less fuel. This means they save you money and reduce your carbon footprint. We offer large rebates for high efficiency furnaces through the Mass Save rebate program, which can make high-efficiency systems more affordable than traditional systems.',
//         question: 'What are the benefits of a high-efficiency system?',
//       },
//       {
//         answer:
//           "One of Tetra's fully vetted, top-quality installers will handle the job. We only work with the best local pros.",
//         question: 'Who is doing the installation?',
//       },
//       {
//         answer:
//           'No. Our pricing is firm. The cost of your project is what you see above—no matter what happens on installation day.',
//         question:
//           'Will I be charged extra if something unexpected happens on installation day?',
//       },
//       {
//         answer:
//           "Your warranty kicks in as soon as your system is installed. Please note, your warranty is through the manufacturer of your system, not through Tetra. Coverage varies depending on the manufacturer, so you'll need to check your specific warranty to see what's covered. You don't have to pay for anything that's covered under your warranty. Many warranties require things like online registration or proof of annual maintenance, so it's smart to check the details of your warranty right away. If you ever have issues with your system or questions about your warranty, please give us a call at (413) 930-4281.",
//         question:
//           "How does the warranty work? If there are any issues, who do I call? Does Tetra fix it? What's covered?",
//       },
//     ],
//     type: 'high_efficiency_furnace',
//   },
//   'BoilerHigh efficiency boiler': {
//     badges: [],
//     description: 'Replace your old boiler with a high efficiency boiler.',
//     exampleImage: HighEfficiencyBoilerExample.src,
//     exampleImages: [
//       {
//         image: GasBoilerNavien0.src,
//         caption: '',
//       },
//       {
//         image: GasBoilerNavien1.src,
//         caption: '',
//       },
//       {
//         image: GasBoilerNavien2.src,
//         caption: '',
//       },
//       {
//         image: GasBoilerNavien3.src,
//         caption: '',
//       },
//       {
//         image: GasBoilerNavien4.src,
//         caption: '',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           "We install forced hot water boilers, steam boilers, and combi boilers (boiler and instant water heater). All our boilers are fueled by gas, oil, or propane. We carry several different brands, which offer varying levels of efficiency. If it's within your budget, we always recommend going for a high efficiency boiler. We offer large rebates through the Mass Save rebate program, which make a high-efficiency model more affordable.",
//         question: 'What types of boilers do you install?',
//       },
//       {
//         answer:
//           'High efficiency systems are quiet, heat your home more comfortably, and use less fuel. This means they save you money and reduce your carbon footprint. We offer large rebates for high efficiency boiler through the Mass Save rebate program, which can make high-efficiency systems more affordable than traditional systems.',
//         question: 'What are the benefits of a high-efficiency system?',
//       },
//       {
//         answer:
//           "One of Tetra's fully vetted, top-quality installers will handle the job. We only work with the best local pros.",
//         question: 'Who is doing the installation?',
//       },
//       {
//         answer:
//           'No. Our pricing is firm. The cost of your project is what you see above—no matter what happens on installation day.',
//         question:
//           'Will I be charged extra if something unexpected happens on installation day?',
//       },
//       {
//         answer:
//           "Your warranty kicks in as soon as your system is installed. Please note, your warranty is through the manufacturer of your system, not through Tetra. Coverage varies depending on the manufacturer, so you'll need to check your specific warranty to see what's covered. You don't have to pay for anything that's covered under your warranty. Many warranties require things like online registration or proof of annual maintenance, so it's smart to check the details of your warranty right away. If you ever have issues with your system or questions about your warranty, please give us a call at (413) 930-4281.",
//         question:
//           "How does the warranty work? If there are any issues, who do I call? Does Tetra fix it? What's covered?",
//       },
//     ],
//     type: 'high_efficiency_boiler',
//   },
//   'BoilerStandard boiler': {
//     badges: [],
//     description: 'Replace your old boiler with a standard efficiency boiler.',
//     exampleImage: StandardBoilerExample.src,
//     exampleImages: [
//       {
//         image: GasBoilerWeilMclain0.src,
//         caption: '',
//       },
//       {
//         image: GasBoilerWeilMclain1.src,
//         caption: '',
//       },
//     ],
//     faq: [
//       {
//         answer:
//           "We install forced hot water boilers, steam boilers, and combi boilers (boiler and instant water heater). All our boilers are fueled by gas, oil, or propane. We carry several different brands, which offer varying levels of efficiency. If it's within your budget, we always recommend going for a high efficiency boiler. We offer large rebates through the Mass Save rebate program, which make a high-efficiency model more affordable.",
//         question: 'What types of boilers do you install?',
//       },
//       {
//         answer:
//           'High efficiency systems are quiet, heat your home more comfortably, and use less fuel. This means they save you money and reduce your carbon footprint. We offer large rebates for high efficiency boiler through the Mass Save rebate program, which can make high-efficiency systems more affordable than traditional systems.',
//         question: 'What are the benefits of a high-efficiency system?',
//       },
//       {
//         answer:
//           "One of Tetra's fully vetted, top-quality installers will handle the job. We only work with the best local pros.",
//         question: 'Who is doing the installation?',
//       },
//       {
//         answer:
//           'No. Our pricing is firm. The cost of your project is what you see above—no matter what happens on installation day.',
//         question:
//           'Will I be charged extra if something unexpected happens on installation day?',
//       },
//       {
//         answer:
//           "Your warranty kicks in as soon as your system is installed. Please note, your warranty is through the manufacturer of your system, not through Tetra. Coverage varies depending on the manufacturer, so you'll need to check your specific warranty to see what's covered. You don't have to pay for anything that's covered under your warranty. Many warranties require things like online registration or proof of annual maintenance, so it's smart to check the details of your warranty right away. If you ever have issues with your system or questions about your warranty, please give us a call at (413) 930-4281.",
//         question:
//           "How does the warranty work? If there are any issues, who do I call? Does Tetra fix it? What's covered?",
//       },
//     ],
//     type: 'standard_efficiency_boiler',
//   },
//   'BoilerSteam boiler': {
//     badges: [],
//     description: 'Replace your old boiler with a standard efficiency boiler.',
//     exampleImage: SteamBoilerExample.src,
//     exampleImages: [],
//     faq: [
//       {
//         answer:
//           "We install forced hot water boilers, steam boilers, and combi boilers (boiler and instant water heater). All our boilers are fueled by gas, oil, or propane. We carry several different brands, which offer varying levels of efficiency. If it's within your budget, we always recommend going for a high efficiency boiler. We offer large rebates through the Mass Save rebate program, which make a high-efficiency model more affordable.",
//         question: 'What types of boilers do you install?',
//       },
//       {
//         answer:
//           "One of Tetra's fully vetted, top-quality installers will handle the job. We only work with the best local pros.",
//         question: 'Who is doing the installation?',
//       },
//       {
//         answer:
//           'No. Our pricing is firm. The cost of your project is what you see above—no matter what happens on installation day.',
//         question:
//           'Will I be charged extra if something unexpected happens on installation day?',
//       },
//       {
//         answer:
//           "Your warranty kicks in as soon as your system is installed. Please note, your warranty is through the manufacturer of your system, not through Tetra. Coverage varies depending on the manufacturer, so you'll need to check your specific warranty to see what's covered. You don't have to pay for anything that's covered under your warranty. Many warranties require things like online registration or proof of annual maintenance, so it's smart to check the details of your warranty right away. If you ever have issues with your system or questions about your warranty, please give us a call at (413) 930-4281.",
//         question:
//           "How does the warranty work? If there are any issues, who do I call? Does Tetra fix it? What's covered?",
//       },
//     ],
//     type: 'steam_boiler',
//   },
//   'BoilerOil boiler': {
//     badges: [],
//     description: 'Replace your oil boiler with another oil boiler.',
//     exampleImage: OilBoilerExample.src,
//     exampleImages: [],
//     faq: [],
//     type: '',
//   },
//   'FurnaceOil furnace': {
//     badges: [],
//     description: 'Replace your oil furnace with another oil furnace.',
//     exampleImage: OilFurnaceExample.src,
//     exampleImages: [],
//     faq: [],
//     type: '',
//   },
//   'Hot water tankOnly hot water tank': {
//     badges: [],
//     description: 'Replace only your existing hot water tank with a new one.',
//     exampleImage: HotWaterTankExample.src,
//     exampleImages: [],
//     faq: [],
//     type: '',
//   },
//   'Air conditionerAir conditioner': {
//     badges: [],
//     description: 'Install only a new central Air Conditioner unit.',
//     exampleImage: ACExample.src,
//     exampleImages: [],
//     faq: [],
//     type: 'air_conditioner',
//   },
//   'BoilerCombination boiler': {
//     badges: [],
//     description: 'Upgrade to a combined heating & hot water boiler.',
//     exampleImage: CombinationBoilerExample.src,
//     exampleImages: [],
//     faq: [],
//     type: 'combination_boiler',
//   },
// };

// interface ImagesBySystemTypeAndManufacturer {
//   [index: string]: {
//     [index: string]: ExampleImage[];
//   };
// }

// const IMAGES_BY_SYSTEM_TYPE_AND_MANUFACTURER: ImagesBySystemTypeAndManufacturer =
//   {
//     air_conditioner: {},
//     central_heat_pump: {
//       bryant: [
//         {
//           image: CHPBryantAirHandler0.src,
//           caption: '',
//         },
//         {
//           image: CHPBryantAirHandler1.src,
//           caption: '',
//         },
//         {
//           image: CHPBryantCondenser0.src,
//           caption: '',
//         },
//         {
//           image: CHPBryantCondenser1.src,
//           caption: '',
//         },
//         {
//           image: CHPBryantCondenser2.src,
//           caption: '',
//         },
//         {
//           image: CHPBryantCondenser3.src,
//           caption: '',
//         },
//       ],
//       ecoer: [
//         {
//           image: CHPECOERAirHandler0.src,
//           caption: '',
//         },
//         {
//           image: CHPECOERAirHandler1.src,
//           caption: '',
//         },
//         {
//           image: CHPECOERCondenser0.src,
//           caption: '',
//         },
//         {
//           image: CHPECOERCondenser1.src,
//           caption: '',
//         },
//         {
//           image: CHPECOERCondenser2.src,
//           caption: '',
//         },
//       ],
//     },
//     combination_boiler: {
//       navien: [
//         {
//           image: CombinationBoilerNavien0.src,
//           caption: '',
//         },
//         {
//           image: CombinationBoilerNavien1.src,
//           caption: '',
//         },
//         {
//           image: CombinationBoilerNavien2.src,
//           caption: '',
//         },
//       ],
//     },
//     steam_boiler: {
//       utica: [],
//       weil_mclain: [],
//     },
//     dms: {
//       gree: [
//         {
//           image: DMSStock1.src,
//           caption:
//             'An outdoor unit with line set connecting it to the indoor unit.',
//         },
//         {
//           image: DMSStock2.src,
//           caption:
//             'An outdoor unit for a heat pump, also known as a condenser.',
//         },
//         {
//           image: DMSStock3.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock4.src,
//           caption: 'An indoor floor unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock5.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//       ],
//       mitsubishi: [
//         {
//           image: DMSStock1.src,
//           caption:
//             'An outdoor unit with line set connecting it to the indoor unit.',
//         },
//         {
//           image: DMSStock2.src,
//           caption:
//             'An outdoor unit for a heat pump, also known as a condenser.',
//         },
//         {
//           image: DMSStock3.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock4.src,
//           caption: 'An indoor floor unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock5.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock6.src,
//           caption: 'A ceiling unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock7.src,
//           caption: 'A ceiling unit for a ductless heat pump.',
//         },
//       ],
//       lg: [
//         {
//           image: DMSStock1.src,
//           caption:
//             'An outdoor unit with line set connecting it to the indoor unit.',
//         },
//         {
//           image: DMSStock2.src,
//           caption:
//             'An outdoor unit for a heat pump, also known as a condenser.',
//         },
//         {
//           image: DMSStock3.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock4.src,
//           caption: 'An indoor floor unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock5.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//       ],
//       carrier: [
//         {
//           image: DMSStock1.src,
//           caption:
//             'An outdoor unit with line set connecting it to the indoor unit.',
//         },
//         {
//           image: DMSStock2.src,
//           caption:
//             'An outdoor unit for a heat pump, also known as a condenser.',
//         },
//         {
//           image: DMSStock3.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock4.src,
//           caption: 'An indoor floor unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock5.src,
//           caption: 'An indoor wall unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock6.src,
//           caption: 'A ceiling unit for a ductless heat pump.',
//         },
//         {
//           image: DMSStock7.src,
//           caption: 'A ceiling unit for a ductless heat pump.',
//         },
//       ],
//     },
//     high_efficiency_furnace: {
//       bryant: [
//         {
//           image: FurnaceBryant0.src,
//           caption: '',
//         },
//         {
//           image: FurnaceBryant1.src,
//           caption: '',
//         },
//         {
//           image: FurnaceBryant2.src,
//           caption: '',
//         },
//       ],
//     },
//     furnace_air_conditioner: {
//       bryant: [
//         {
//           image: FurnaceACBryantCondenser0.src,
//           caption: '',
//         },
//         {
//           image: FurnaceACBryantFurnace0.src,
//           caption: '',
//         },
//         {
//           image: FurnaceACBryantFurnace1.src,
//           caption: '',
//         },
//         {
//           image: FurnaceACBryantFurnace2.src,
//           caption: '',
//         },
//       ],
//     },
//     high_efficiency_furnace_heat_pump: {
//       bryant: [],
//     },
//     high_efficiency_boiler: {
//       navien: [
//         {
//           image: GasBoilerNavien0.src,
//           caption: '',
//         },
//         {
//           image: GasBoilerNavien1.src,
//           caption: '',
//         },
//         {
//           image: GasBoilerNavien2.src,
//           caption: '',
//         },
//         {
//           image: GasBoilerNavien3.src,
//           caption: '',
//         },
//         {
//           image: GasBoilerNavien4.src,
//           caption: '',
//         },
//       ],
//     },
//     standard_efficiency_boiler: {
//       'weil-mclain': [
//         {
//           image: GasBoilerWeilMclain0.src,
//           caption: '',
//         },
//         {
//           image: GasBoilerWeilMclain1.src,
//           caption: '',
//         },
//       ],
//     },
//     standard_efficiency_furnace_heat_pump: {
//       bryant: [],
//     },
//   };

// type WaterHeaterImages = {
//   [key: string]: { [key: string]: string };
// };

// const WATER_HEATER_IMAGES: WaterHeaterImages = {
//   'Heat Pump water heater': {
//     'AO Smith': AOSmithHeatPumpWaterHeater.src,
//     Rheem: RheemHeatPumpWaterHeater.src,
//   },
//   'Standard gas storage tank': {
//     HTP: HTPStandardGasStorageTank.src,
//   },
//   'Tankless hot water heater': {
//     Navien: NavienTanklessHotWaterHeater.src,
//   },
//   'Indirect hot water heater': {
//     IBC: IBCIndirectHotWaterHeater.src,
//   },
//   'Electric resistance water heater': {
//     Rheem: RheemEletricResistanceWaterHeater.src,
//   },
// };

// type EquipmentImagesBySystemTypeAndManufacturer<
//   K extends string | number | symbol
// > = {
//   [k in K]: {
//     // system type
//     [index: string]: {
//       // manufacturer
//       [index: string]: {
//         // model
//         [index: string]: {
//           // equipment type
//           [index: string]: string;
//         };
//       };
//     };
//   };
// };

// const EQUIPMENT_IMAGES_BY_SYSTEM_TYPE_AND_MANUFACTURER: EquipmentImagesBySystemTypeAndManufacturer<SystemType> =
//   {
//     air_conditioner: {
//       Bryant: {
//         Evolution: {
//           airHandler: {
//             src: CHPBryantAirhandler.src, // use the same image as chp
//             alt: 'Bryant air handler',
//           },
//           condenser: {
//             src: ACBryantEvolutionCondenser.src,
//             alt: 'Bryant Evolution condenser',
//           },
//         },
//         Legacy: {
//           airHandler: {
//             src: ACBryantLegacyAirhandler.src,
//             alt: 'Bryant Legacy air handler',
//           },
//           condenser: {
//             src: ACBryantLegacyCondenser.src,
//             alt: 'Bryant Legacy condenser',
//           },
//         },
//         Preferred: {
//           airHandler: {
//             src: ACBryantPreferredAirhandler.src,
//             alt: 'Bryant Preferred air handler',
//           },
//           condenser: {
//             src: ACBryantPreferredCondenser.src,
//             alt: 'Bryant Preferred condenser',
//           },
//         },
//       },
//     },
//     central_heat_pump: {
//       ECOER: {
//         '': {
//           airHandler: {
//             src: CHPEcoerAirhandler.src,
//             alt: 'Ecoer air handler',
//           },
//           condenser: {
//             src: CHPEcoerCondenser.src,
//             alt: 'Ecoer condenser',
//           },
//         },
//       },
//       Bryant: {
//         'Evolution Extreme': {
//           airHandler: {
//             src: CHPBryantAirhandler.src,
//             alt: 'Bryant air handler',
//           },
//           condenser: {
//             src: CHPBryantCondenser.src,
//             alt: 'Bryant condenser',
//           },
//         },
//       },
//     },
//     combination_boiler: {
//       Navien: {
//         NCB: {
//           boiler: {
//             src: buildSystemImageString('Navien', 'combination_boiler', 'NCB'),
//             alt: 'Navien combination boiler',
//           },
//         },
//       },
//       IBC: {
//         SFC: {
//           boiler: {
//             src: buildSystemImageString('IBC', 'combination_boiler', 'SFC'),
//             alt: 'IBC combination boiler',
//           },
//         },
//       },
//       Bosch: {
//         Greenstar: {
//           boiler: {
//             src: buildSystemImageString(
//               'Bosch',
//               'combination_boiler',
//               'Greenstar'
//             ),
//             alt: 'Bosch combination boiler',
//           },
//         },
//       },
//     },
//     dms: {},
//     steam_boiler: {
//       Utica: {
//         '': {
//           boiler: {
//             src: buildSystemImageString('Utica', 'steam_boiler', ''),
//             alt: 'Utica steam boiler',
//           },
//         },
//       },
//       'Weil-McLain': {
//         '': {
//           boiler: {
//             src: buildSystemImageString('Weil-McLain', 'steam_boiler', ''),
//             alt: 'Weil-McLain steam boiler',
//           },
//         },
//       },
//     },
//     high_efficiency_furnace: {
//       'American Standard': {
//         Silver: {
//           furnace: {
//             src: FurnaceAmericanStandardSilver.src,
//             alt: 'American standard furnace',
//           },
//         },
//       },
//       Bryant: {
//         Evolution: {
//           furnace: {
//             src: buildSystemImageString(
//               'Bryant',
//               'high_efficiency_furnace',
//               'Evolution'
//             ),
//             alt: 'Bryant Evolution furnace',
//           },
//         },
//         Legacy: {
//           furnace: {
//             src: buildSystemImageString(
//               'Bryant',
//               'high_efficiency_furnace',
//               'Legacy'
//             ),
//             alt: 'Bryant Legacy furnace',
//           },
//         },
//       },
//     },
//     furnace_air_conditioner: {
//       'American Standard': {
//         Silver: {
//           furnace: {
//             src: FurnaceAmericanStandardSilver.src,
//             alt: 'American Standard furnace',
//           },
//           condenser: {
//             src: FurnaceACAmericanStandardCondenser.src,
//             alt: 'American Standard condenser',
//           },
//         },
//       },
//       Bryant: {
//         Evolution: {
//           furnace: {
//             src: FurnaceACBryantLegacyFurnace.src,
//             alt: 'Bryant furnace',
//           },
//           condenser: {
//             src: FurnaceACBryantEvolutionCondenser.src,
//             alt: 'Bryant Evolution condenser',
//           },
//         },
//         Legacy: {
//           furnace: {
//             src: FurnaceACBryantLegacyFurnace.src,
//             alt: 'Bryant Legacy furnace',
//           },
//           condenser: {
//             src: FurnaceACBryantLegacyCondenser.src,
//             alt: 'Bryant Legacy condenser',
//           },
//         },
//       },
//     },
//     high_efficiency_furnace_heat_pump: {
//       Bryant: {
//         'Evolution Extreme': {
//           furnace: {
//             src: FurnaceACBryantLegacyFurnace.src,
//             alt: 'Bryant furnace',
//           },
//           condenser: {
//             src: FurnaceHPBryantEvolutionExtremeCondenser.src,
//             alt: 'Bryant condenser',
//           },
//         },
//         Preferred: {
//           furnace: {
//             src: FurnaceACBryantLegacyFurnace.src,
//             alt: 'Bryant furnace',
//           },
//           condenser: {
//             src: FurnaceHPBryantPreferredCondenser.src,
//             alt: 'Bryant Preferred condenser',
//           },
//         },
//       },
//     },
//     high_efficiency_boiler: {
//       Navien: {
//         NHB: {
//           boiler: {
//             src: buildSystemImageString(
//               'Navien',
//               'high_efficiency_boiler',
//               'NHB'
//             ),
//             alt: 'Navien high efficiency boiler',
//           },
//         },
//       },
//       IBC: {
//         HC: {
//           boiler: {
//             src: buildSystemImageString('IBC', 'high_efficiency_boiler', 'HC'),
//             alt: 'IBC high efficiency boiler',
//           },
//         },
//       },
//     },
//     standard_efficiency_boiler: {
//       'Weil-McLain': {
//         '': {
//           boiler: {
//             src: buildSystemImageString(
//               'Weil-McLain',
//               'standard_efficiency_boiler',
//               ''
//             ),
//             alt: 'Weil-McLain standard efficiency boiler',
//           },
//         },
//       },
//     },
//     standard_efficiency_furnace_heat_pump: {
//       Bryant: {
//         Evolution: {
//           furnace: {
//             src: FurnaceACBryantLegacyFurnace.src,
//             alt: 'Bryant furnace',
//           },
//           condenser: {
//             src: FurnaceHPBryantEvolutionExtremeCondenser.src,
//             alt: 'Bryant condenser',
//           },
//         },
//       },
//     },
//   };

export {
  HOT_JAR_IDS,
  ECOMMERCE_ENVIRONMENTS_URLS,
  PAGE_SIZE,
  CONTRACTOR_DASHBOARD_URL,
  CONTRACTOR_LOGIN_TRACKING_EVENT,
  HOMEOWNER_DASHBOARD_URL,
  HOMEOWNER_LOGIN_TRACKING_EVENT,
  // GOALS_RANKING_CARDS,
  // IMAGES_BY_SYSTEM_TYPE_AND_MANUFACTURER,
  // WATER_HEATER_IMAGES,
  // EQUIPMENT_IMAGES_BY_SYSTEM_TYPE_AND_MANUFACTURER,
};

export const ZERO_PERCENT_INTEREST_INFO_TITLE = '0% interest financing';

export const ZERO_PERCENT_INTEREST_INFO =
  "In addition to rebates, the Mass Save program also provides a 0% interest loan (HEAT Loan) for all energy-related home upgrades. If you'd like to take advantage of the program, we'll submit your application and walk you through the financing process.";

// non-breaking space
export const NBSP = '\u00A0';

/* FROM SALES_QUOTE_FRONTEND */
export interface Doc_Upload_Section_Info {
  [key: string]: { title: string; subtitle: string };
}

export const DOC_UPLOAD_TABS = {
  tetra_docs: 'Tetra docs',
  utility_info: 'Utility Info',
  installation_images: 'Installation Images',
};
export const DOC_UPLOAD_TABS_KEYS = Object.keys(DOC_UPLOAD_TABS);
export const DOC_UPLOAD_TABS_VALUES = Object.values(DOC_UPLOAD_TABS);

export const CUSTOMER_INFORMATION = {
  name: 'Name',
  phone: 'Phone',
  customer_address: 'Address',
  email: 'Email',
  proposal_id: 'Quote ID',
  license_number: 'License Number',
};

export const QUOTE_INFORMATION = {
  customer_name: 'Customer Name',
  contractor: 'Contractor',
  customer_address: 'Address',
  manager_address: 'Address',
  phone: 'Phone',
  consultant_name: 'Consultant',
  email: 'Email',
  company_id: 'Company ID',
  id: 'Quote ID',
};

export const INVOICE_INFORMATION = {
  customer_name: 'Customer Name',
  contractor: 'Contractor',
  customer_address: 'Address',
  manager_address: 'Address',
  phone: 'Phone',
  consultant_name: 'Consultant',
  email: 'Email',
  company_id: 'Company ID',
  id: 'Invoice Number',
  license_number: 'License Number',
};

export const WORK_ORDER_INFORMATION = {
  customer_name: 'Name',
  phone: 'Phone',
  customer_address: 'Address',
  email: 'Email',
};

export const HOME_INFORMATION = {
  fully_finished_basement: 'Basement',
  fuel_type: 'Fuel type',
  siding_type: 'Siding type',
  zones: 'Zones',
};

export const TAX_INCENTIVE = 2000;
export const DEPOSIT = 100;

export const MASS_SAVE_PARTNER_ELECTRIC_AND_GAS_PROVIDERS = [
  'Eversource Energy (NSTAR Electric)',
  'National Grid (Massachusetts Electric)',
  'Unitil (Fitchburg Gas & Electric)',
  'Berkshire Gas',
  'Eversource Energy (EGMA)',
  'Liberty Utilities',
  'National Grid (Boston Gas)',
];

/* END FROM SALES_QUOTE_FRONTEND */
