import { init } from '@amplitude/analytics-browser';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { Wrapper as MapsWrapper } from '@googlemaps/react-wrapper';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { NextPage } from 'next';
import { ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';

import LayoutWrapper from 'components/Layout/LayoutWrapper';
import { persistor, store } from 'redux/store';
import { ECOMMERCE_ENVIRONMENTS_URLS } from 'utils/constants';
import createEmotionCache from 'utils/createEmotionCache';
import theme from 'utils/theme';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RouteGuard from 'components/RouteGuard';
import LeadQualificationErrorFallback from 'components/ErrorFallback';
import { initializeGtm } from 'utils/google-tag-manager';
import { useRouter } from 'next/router';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (children: ReactNode) => ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

declare global {
  interface Window {
    klaviyo: any;
  }
}

const clientSideEmotionCache = createEmotionCache();

if (typeof window !== 'undefined') {
  initializeGtm();
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout;
  const router = useRouter();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (window?.location?.origin === ECOMMERCE_ENVIRONMENTS_URLS.PRODUCTION ||
        window?.location?.origin === ECOMMERCE_ENVIRONMENTS_URLS.STAGING ||
        window?.location?.origin.includes('localhost'))
    ) {
      init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
        serverUrl: process.env.NEXT_PUBLIC_AMPLITUDE_SERVER_URL,
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  // @ts-ignore
  const ErrorFallBack = ({ error }) => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
      setShowNotification(true);

      if (showNotification) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      }
    }, [showNotification, error]);

    if (location.pathname.startsWith('/lead-qualification/')) {
      return <LeadQualificationErrorFallback />;
    }

    return null;
  };

  return (
    <CacheProvider value={emotionCache}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <title>
              {router.asPath.startsWith('/rebate-eligibility')
                ? 'Find out how much you qualify for in rebates'
                : 'Tetra | Shop'}
            </title>
          </Head>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <MapsWrapper
                /* @ts-ignore */
                apiKey={process.env.NEXT_PUBLIC_GOOGLEMAPS_API_KEY}
                libraries={['places']}
              >
                <RouteGuard>
                  {getLayout ? (
                    <>
                      <ToastContainer />
                      <ErrorBoundary FallbackComponent={ErrorFallBack}>
                        <LayoutWrapper getLayout={getLayout}>
                          <Component {...pageProps} />
                        </LayoutWrapper>
                      </ErrorBoundary>
                    </>
                  ) : (
                    <>
                      <ToastContainer />
                      <ErrorBoundary FallbackComponent={ErrorFallBack}>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </>
                  )}
                </RouteGuard>
              </MapsWrapper>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </CacheProvider>
  );
}

export default MyApp;
