import Image from 'next/image';

import Logo from 'public/images/logo/tetra-header-logo.png';
import styles from './styles.module.css';
import LeadOwnerCalendly from 'components/LeadOwnerCalendly';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const ErrorFallback = () => {
  const phone = useSelector<RootState>(
    (state) => state.onboarding.contactInfo?.phone
  ) as string;

  if (!phone) {
    return null;
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo_container}>
        <div className={styles.logo}>
          <Image alt="logo" height={24} src={Logo} width={86} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.title}>Let's connect</div>
        <div className={styles.text}>
          We'd like to give you a more personalized experience. Schedule time to
          meet with your Tetra Concierge and we'll assist you with your heating
          and cooling needs.
        </div>
        <LeadOwnerCalendly />
        <div className={styles.help}>
          Want help now?{' '}
          <a className={styles.link} href="mailto:contact@tetra.com">
            Email us
          </a>{' '}
          or call our customer concierge line at{' '}
          <a className={styles.link} href="tel:+13393370109">
            (339) 337-0109
          </a>{' '}
          and we'll help.
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
